var $menuIcon = $('header .menu-wrapper .menu-button');
var $header = $('header');
var $body = $('body');
let $removeBg = $('remove-bg');
let $menuText = document.getElementById('menu-text');

var $navMenu = $('header .menu-wrapper .nav-menu');

if ($menuIcon) {
    $menuIcon.click(function () {
        if ($menuIcon.hasClass('menu-open')) {
            $menuIcon.removeClass('menu-open');
            $header.removeClass('show-menu');
            $body.removeClass('no-scroll-y');
            $menuText.textContent = 'Meny';
            if ($header.hasClass('remove-bg')) {
                $header.removeClass('bg-blue');
            }
            $navMenu.css({
                'max-height': '0'
            })
        } else {
            $menuIcon.addClass('menu-open');
            $header.addClass('show-menu');
            $header.addClass('bg-blue');
            $body.addClass('no-scroll-y');
            $menuText.textContent = 'Lukk';
            $navMenu.css({
                'max-height': ('calc(var(--vh, 1vh) * 100)')
            })
        }
    });
}